<template>
  <div class="case-conent">
    <div class="hd">
      <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini"  plain round>返回</el-button></div>
      知识内容测评
    </div>
    <!--学历案日志列表-->
    <div class="table-content">
      <div class="table">
        <el-table
            border
            :header-cell-style="{background:'#D1EEFE',color:'#333'}"
            :data="listData"
        >
          <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
          <el-table-column prop="knowledge" label="知识内容" header-align="center"></el-table-column>
<!--          <el-table-column prop="subjectName" label="学科" header-align="center" width="80"></el-table-column>-->
          <el-table-column prop="" label="掌握情况" header-align="center" width="200">
            <template slot-scope="scope">
              <span style="color: #FF4D00" v-if="scope.row.isRight=='未掌握'">{{scope.row.isRight}}</span>
              <span v-if="scope.row.isRight=='掌握较好'">{{scope.row.isRight}}</span>
              <span v-if="scope.row.isRight=='基本掌握'">{{scope.row.isRight}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <div class="pag-box" v-if="code==200">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>

    </div>
  </div>
</template>


<script>

import {getParContentKnowledgeListObj} from '@/api/parent'
export default {
  name: '',
  data() {
    return {
      clickFlag:null,
      par:null,
      SubjectData: [],
      evaluation:{
        subject:''
      },
      listData:[],

    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag = this.$route.query.clickFlag;
    this.par = this.$route.query.par;
    this.testPaperId = this.$route.query.testPaperId;
    this.listObj()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },
    // 学科
    subjectChange($event){
      sessionStorage.setItem('bookRegionStatus',$event)
    },
    // 清除学科
    subjectClear() {
      this.evaluation.subject = ''
      sessionStorage.removeItem('bookRegionStatus');
      // this.getList()
    },
    //充值详情弹框
    rechargeObj(scope) {
      this.d1= scope.row
      this.rechargeDialog=true
    },
    closRechargeObj() {
      this.rechargeDialog=false
    },
    //购买详情弹框
    buyObj(scope) {
      this.d2= scope.row
      this.buyDialog=true
    },
    closbuyObj() {
      this.buyDialog=false
    },


    //查询列表
    listObj() {
      let paperId= this.testPaperId
      let obj = {
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      getParContentKnowledgeListObj(obj,paperId).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.listData = res.data.records
          this.code = res.code;
          this.pageSize = res.data.size;
          this.currentPage = res.data.current;
          this.total = res.data.total
        } else if(res.code==209) {
          this.code = res.code;
        }
      })
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.listObj();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.listObj();
    },
    // 去详情
    tosDetails() {
      this.$router.push({
        path: "/mainb/details",
        query: {
          EducationCase:this.EducationCase,
          clickFlag:0
        }
      });
    },
    // 去详情
    toTimeline() {
      this.$router.push({
        path: "/mainb/timeline",
        query: {
          EducationCase:this.EducationCase,
          clickFlag:1
        }
      });
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
    position: relative;
    .back {
      position: absolute;
      justify-content: flex-start;
      padding-left: 20px;
      box-sizing: border-box;
      .back-btn {
        border: 1px solid #c4e7ff;padding: 0px 20px; height:32px;line-height: 32px; background-color: #37AEFF; color:#c4e7ff
      }
    }
  }

  .table-content {
    width: 96%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
